import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { progressOnScroll, setOn, useFadeOnScroll } from "../helpers";
import {
  SectionWrapper,
  ProgressNavbar,
  IntroSection,
  FeedRoll,
  ArtistsRoll,
  ReleasesRoll,
  Footer,
  AboutSection,
  Layout,
} from "../components";

export const IndexPageTemplate = ({ intro, titles, about, isPreview = false }) => {
  // prettier-ignore
  const initialNavItems: any = {
    home: { id: "home", ref: React.useRef<HTMLDivElement>(null) },
    about: { id: "about", ref: React.useRef<HTMLDivElement>(null) },
    // feed: { id: titles.feed, ref: React.useRef<HTMLDivElement>(null) },
    creators: { id: titles.artists, ref: React.useRef<HTMLDivElement>(null) },
    releases: { id: titles.releases, ref: React.useRef<HTMLDivElement>(null) },
    contact: { id: "contact", ref: React.useRef<HTMLDivElement>(null) },
  };

  useFadeOnScroll(".section__homepage");

  const [scrollNavData, setScrollNavData] = React.useState(initialNavItems);
  const scrollEvent = (): void =>
    progressOnScroll(scrollNavData, setScrollNavData);

  React.useEffect(() => {
    setOn("home", scrollNavData, setScrollNavData);
    window.addEventListener("scroll", scrollEvent);

    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  return (
    <React.Fragment>
      {/* Navbar */}
      <ProgressNavbar data={scrollNavData} />
      {/* Intro */}
      <IntroSection data={intro} ref={initialNavItems.home.ref} />
      {/* About */}
      <AboutSection
        data={about}
        className="section__homepage"
        ref={initialNavItems.about.ref}
        isPreview={isPreview}
      />
      {/* Feed */}
      {/* <SectionWrapper
        title={titles.feed}
        ref={initialNavItems.feed.ref}
        className="section__homepage cross"
        link={"/feed"}
      >
        <FeedRoll />
      </SectionWrapper> */}
      {/* Creators */}
      <SectionWrapper
        title={titles.artists}
        ref={initialNavItems.creators.ref}
        className="section__homepage cross"
        link={"/artists"}
      >
        <ArtistsRoll />
      </SectionWrapper>
      {/* Releases */}
      <SectionWrapper
        title={titles.releases}
        ref={initialNavItems.releases.ref}
        className="section__homepage cross min-height"
        link={"/releases"}
      >
        <ReleasesRoll />
      </SectionWrapper>
      {/* Contact */}
      <SectionWrapper
        title={"Contact"}
        ref={initialNavItems.contact.ref}
        className="section__homepage cross min-height"
      >
        <Footer className={"max-height no-padding"} />
      </SectionWrapper>
    </React.Fragment>
  );
};

IndexPageTemplate.propTypes = {
  intro: PropTypes.shape({
    feed: PropTypes.string,
    artists: PropTypes.string,
    releases: PropTypes.string,
  }),
  titles: PropTypes.object,
  about: PropTypes.shape({
    title: PropTypes.string,
    bio: PropTypes.string,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout ignoreFooter ignoreNav ignoreAnimations>
      <IndexPageTemplate
        intro={frontmatter.intro}
        titles={frontmatter.titles}
        about={frontmatter.about}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        intro {
          heading
          description
        }
        titles {
          feed
          artists
          releases
        }
        about {
          title
          bio
          slides {
            src {
              childImageSharp {
                fluid(
                  maxWidth: 800
                  quality: 100
                  srcSetBreakpoints: [250, 400, 600, 800]
                ) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`;
